import React, {useState, useContext} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ObzInput from './ObzInput.js';
import {FormContext} from './Contexts.js';

function makeid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 10) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export default function ObzInputGroup({onChange, obzStateInit}) {

    const formContext = useContext(FormContext);
    const obzList = formContext.formMetadata.obzList;

    const [state, setState] = useState(obzStateInit ? obzStateInit: {});

    const [allowed, setAllowed] = useState(obzList);

    const [ready, setReady] = useState(true);

    const updateTopState = (stateNew) => {  
        const stateNewFiltered = Object.entries(stateNew)
            .filter(([_, obzState]) => (obzState?.data !== undefined && obzState.data !== 0) 
                || obzState?.dataJoined !== undefined && obzState.dataJoined !== 0)
            .map(([_, obzState]) => {return {targetObzId: obzState.id, value: obzState.data, valueJoined: obzState.dataJoined, teamLead: obzState.teamLead}})
        
        onChange({"obzList": stateNewFiltered, "obzState": stateNew});
    };

    const recomputeAllowed = (state) => {
        const usedObz = Object.entries(state)
        .filter(([_, obzState]) => obzState?.id !== undefined && obzState?.id !== null)
        .map(([_, obzState]) => String(obzState.id))

        var recomputed = obzList.filter(obz => !usedObz.includes(String(obz.id)))
        setAllowed(recomputed)
        return recomputed;
    }
    const onEntityChange = (id, newEntity) => {
        var newState = null
        const justRemoved = newEntity == null
        if (!justRemoved) {
            newState = {
                ...state,
                [id]: newEntity
            }
        } else {
            newState = state
            delete newState[id]
            if (emptyOrAllDisabled(newState, allowed)) {
                recomputeAllowed(newState)
            }
            // recomputeAllowedJoined(newState)
        }

        setState(newState)
        updateTopState(newState)
    }

    const add = () => {
        var allowed = recomputeAllowed(state)
        setReady(false)
        onEntityChange(makeid(), allowed[0], false)
    }

    const emptyOrAllDisabled = (state, allowed) => {
        var sizeAll = Object.entries(state).length
        var sizeDisabled = Object.entries(state).filter(([id, obz]) => obz?.disabled != null && obz?.disabled).length
        return sizeAll === 0 || (sizeAll === sizeDisabled && allowed.length > 0)
    }

    return (
        <>
            <Form.Group >
                <Form.Label>ОБЗ (Оперативно Бойові Заходи)</Form.Label>
            </Form.Group>
            
            {Object.entries(state)
            .map(([id, obzState]) => 
            <ObzInput
                name={"inputObz"}
                allowedObz={allowed}
                obzState={obzState} 
                key={id}
                onChange={(u) => onEntityChange(id, u)}
                notifyReady={() => {
                    recomputeAllowed(state)
                    setReady(true)}}/>)}
            {(allowed.length > 0 && ready) || emptyOrAllDisabled(state, allowed)
            ? <Button id="addObzBtn" size="sm" variant="primary" className="small-button" onClick={add}>Додати</Button>
            : <></>}
        </>
    );
}